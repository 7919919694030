import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import ActionButton from "../components/ActionButton/index"
import style from "./not-found.module.css"

const NotFoundPage = () => {

  return (
    <>
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>404 NOT FOUND</title>
          <meta
            name="description"
            content="Shipamax | Specialised supply chain data extraction platform"
          />
        </Helmet>

        <div className={style.root}>
          <div className={style.banner}>
            <div className="container">
              <div className="row">
                <div className="col s12">
                  <div className={style.banner__content}>
                    <h1 className={style.banner__heading}>
                      404 PAGE NOT FOUND
                    </h1>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <div className={style.homeButton}>
                    <ActionButton label={"HOME"} url={"/"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default NotFoundPage
